<template>
  <v-app id="inspire">
    <v-navigation-drawer
      :mini-variant.sync="mainSidebarDrawer"
      class="pt-4"
      permanent
      dark
      color="#212121"
      expand-on-hover
      app
      :mini-variant-width="80"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar size="60">
            <v-img src="@/assets/logo.png" width="60" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <div>Tolbot <strong>Back Office</strong></div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item-group v-model="selected">
          <v-list-group
            v-for="item in filterItems(getItems())"
            v-model="item.active"
            :class="
              item.children.length == 0
                ? 'grouped-items grouped-items-no-child menu-item'
                : 'grouped-items menu-item'
            "
            :key="`${item.title}-group`"
            link
            :prepend-icon="item.icon"
            :append-icon="item.children.length == 0 ? '' : '$expand'"
            no-action
            @click.stop="go(item)"
          >
            <template v-slot:activator>
              <v-list-item-content style="min-height: 50px">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, i) in filterItems(item.children)"
              :key="i"
              link
              @click.stop="go(child)"
            >
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- </template> -->
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            v-if="!mainSidebarDrawer"
            block
            color="light-blue darken-4"
            @click="logout"
          >
            <v-icon left> mdi-logout </v-icon>
            Cerrar sesión
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main class="main-container">
      <top-nav />
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import loginService from "@/services/login.service";
import TopNav from "./TopNav.vue";
import { mapActions } from "vuex";
export default {
  name: "private-layout",
  components: {
    TopNav,
  },
  data: () => ({
    selected: null,
    mainSidebarDrawer: true,
  }),
  computed: {},
  watch: {
    mainSidebarDrawer: {
      handler(val) {
        console.log(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.initConnection();
    this.selected = this.$route.path;
  },
  methods: {
    ...mapActions("communication", ["initConnection", "sendMessage"]),
    getItems() {
      return [
        // {
        //   title: "Dashboard",
        //   path: "/dashboard",
        //   icon: "mdi-home-outline",
        //   children: [],
        //   key: "menu-dashboard",
        // },
        {
          title: "Peaje",
          icon: "mdi-boom-gate",
          active: false,
          key: "menu-toll",
          children: [
            {
              title: "Arqueo",
              icon: "mdi-counter",
              path: "/toll-snapshots",
              key: "menu-toll-snapshots",
            },
            {
              title: "Transacciones",
              icon: "mdi-car-side",
              path: "/toll-transactions",
              key: "menu-toll-transactions",
            },
            {
              title: "Carriles",
              icon: "mdi-highway",
              path: "/toll-lanes",
              key: "menu-toll-lanes",
            },
            {
              title: "Pre-Liquidación",
              icon: "mdi-book-lock-open",
              path: "/toll-settlement-pre",
              key: "menu-toll-settlement-pre",
            },
            {
              title: "Liquidación",
              icon: "mdi-book-lock",
              path: "/toll-settlement",
              key: "menu-toll-settlement",
            },
          ],
        },
        {
          title: "Telepeaje",
          icon: "mdi-radar",
          active: false,
          key: "menu-electronic-toll",
          children: [
            {
              title: "Recepción",
              icon: "mdi-open-in-app",
              path: "/etc-receive",
              key: "menu-electronic-toll-reception",
            },
            {
              title: "Envío",
              icon: "mdi-send-outline",
              path: "/etc-delivery",
              key: "menu-electronic-toll-delivery",
            },
            {
              title: "Cuentas",
              icon: "mdi-cards-outline",
              path: "/etc-items",
              key: "menu-electronic-toll-accounts",
            },
          ],
        },
        {
          title: "Reportes",
          path: "/reports",
          icon: "mdi-file-chart",
          children: [],
          key: "menu-reports",
        },
        {
          title: "Export",
          active: false,
          icon: "mdi-database-export",
          key: "menu-export",
          children: [
            {
              title: "Video",
              icon: "mdi-file-video",
              path: "/export-video",
              key: "menu-export-video",
            },
            {
              title: "Facturación",
              icon: "mdi-file-document-outline",
              path: "/export-billing",
              key: "menu-export-billing",
            },
            {
              title: "Facturación Grupo",
              icon: "mdi-file-document-outline",
              path: "/export-billing-group",
              key: "menu-export-billing-group",
            },
            {
              title: "Aforo",
              icon: "mdi-counter",
              path: "/export-traffic",
              key: "menu-export-traffic",
            },
            {
              title: "Aforo Grupo",
              icon: "mdi-counter",
              path: "/export-traffic-group",
              key: "menu-export-traffic-group",
            },
          ],
        },
        {
          title: "Catálogos",
          icon: "mdi-folder-open-outline",
          active: false,
          key: "menu-catalogs",
          children: [
            {
              title: "Turnos",
              icon: "mdi-calendar-account-outline",
              path: "/catalogs-turns",
            },
            {
              title: "Formas Pago",
              icon: "mdi-cash",
              path: "/catalogs-payment-types",
            },
            {
              title: "Clasificación",
              icon: "mdi-clipboard-list",
              path: "/catalogs-classification",
            },
            {
              title: "Carriles",
              icon: "mdi-store-cog-outline",
              path: "/catalogs-gates",
            },
            {
              title: "Sensores",
              icon: "mdi-wifi",
              path: "/catalogs-devices",
            },
            {
              title: "Exceptuados",
              icon: "mdi-shield-car",
              path: "/catalogs-excepted",
            },
          ],
        },
        {
          title: "Monitoreo",
          active: false,
          icon: "mdi-chart-donut-variant",
          key: "menu-monitor",
          children: [
            {
              title: "NVR Server",
              icon: "mdi-video",
              path: "/monitor-video",
            },
          ],
        },
        {
          title: "Administración",
          icon: "mdi-cog-outline",
          key: "menu-admin",
          children: [
            {
              title: "Usuarios",
              icon: "mdi-account-group",
              path: "/admin-users",
              key: "menu-admin-users",
            },
            {
              title: "Roles",
              icon: "mdi-format-list-text",
              path: "/admin-roles",
              key: "menu-admin-roles",
            },
            {
              title: "Permisos",
              icon: "mdi-shield-lock",
              path: "/admin-permissions",
              key: "menu-admin-permissions",
            },
          ],
        },
      ];
    },
    go(item) {
      this.$router.push(item.path);
    },
    collapseSubItems() {
      this.items.map((item) => (item.active = false));
    },
    logout() {
      loginService.logout();
    },
    filterItems(items) {
      var permissions = loginService.getPermissions().map((p) => p.key);

      return items.filter((item) => {
        if (item.key === undefined) return true;
        return permissions.includes(item.key);
      });
    },
  },
};
</script>

<style scoped>
.main-container {
  background-color: #f3f6fb !important;
  padding: 0px 0px 0px 80px !important;
}
</style>